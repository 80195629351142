.footer {
    background-color: #23272c;
    position: relative;
    overflow: hidden;

    .footerBackground{
        position: absolute;
        background-image: url('/images/footerBackground.png');
        right: -95px;
        bottom: 0;
        height: 474px;
        width: 542px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;

        @media(max-width: 991px){
            display: none;
        }
    }

    .firstLevel {
        padding: 91px 0px 67px 0px;

        @media (max-width: 991px) {
            padding: 30px 0px;
        }

        .logo {
            text-align: center;

            img {
                width: 333px;
            }
        }

        .contactData {
            .wrapperBoxData {
                display: flex;
                justify-content: center;

                .boxData {
                    @media (min-width: 992px) {
                        margin: 0px 30px;
                    }

                    .title {
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 22px;
                        letter-spacing: 0em;
                        color: #fff;
                        margin-bottom: 38px;
                        margin-left: -15px;

                        @media (max-width: 991px) {
                            margin-bottom: 30px;
                            margin-top: 30px;
                            margin-left: 0;
                            text-align: center;
                        }
                    }

                    .itemData {
                        margin-bottom: 30px;
                        text-align: center;

                        a {
                            font-size: 17px;
                            font-style: normal;
                            font-weight: 300;
                            line-height: 21px;
                            letter-spacing: 0em;
                            text-align: left;
                            color: #fff;
                            transition: all 0.5s ease;

                            &:hover,
                            &:focus {
                                color: #cf9c57;
                            }

                            .boxIntern {
                                display: flex;
                                align-items: start;

                                @media(max-width: 991px){
                                    justify-content: center;
                                }

                                .imgWhatsapp {
                                    width: 15px;
                                    margin-right: 7px;
                                }

                                .imgPhone {
                                    width: 15px;
                                    margin-right: 7px;
                                }

                                .imgLocation {
                                    width: 10px;
                                    margin-right: 10px;
                                }
                            }
                        }
                    }
                }
            }
        }

        .iframe {
        }
    }

    .secondLevel {
        border-top: 1px #cf9c57 solid;
        min-height: 85px;
        padding: 15px 0px;
        display: flex;
        align-items: center;
        justify-content: center;

        @media (max-width: 991px) {
            flex-direction: column;
        }

        .left {
            position: relative;

            .privacyPolicy {
                text-align: center;

                @media (max-width: 991px) {
                    margin-bottom: 10px;
                }

                a {
                    font-family: "Roboto", sans-serif;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 22px;
                    letter-spacing: 0em;
                    text-align: center;
                    color: #fff;
                    transition: all 0.5s ease-in-out;

                    &:hover,
                    &:focus {
                        color: #cf9c57;
                    }
                }
            }

            .copyright {
                text-align: center;
                font-family: "Roboto", sans-serif;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px;
                letter-spacing: 0em;
                text-align: center;
                color: #fff;

                a {
                    text-align: center;
                    font-family: "Roboto", sans-serif;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 22px;
                    letter-spacing: 0em;
                    text-align: center;
                    color: #fff;
                    transition: all 0.5s ease;

                    &:hover,
                    &:focus {
                        color: #cf9c57;
                    }
                }
            }

            .socialMedia {
                position: absolute;
                right: -148px;
                top: calc(50% - 12px);
                display: flex;
                align-items: center;

                @media (max-width: 991px) {
                    position: relative;
                    right: 0;
                    top: 0;
                    margin-top: 10px;
                    justify-content: center;
                }

                a{
                    display: flex;
                    align-items: center;
                }

                .imgFacebook {
                    margin-right: 14px;
                    width: 10px;
                }

                .imgInstagram {
                    width: 19px;
                }
            }
        }
    }
}

.whatsappButton {
    opacity: 0;
    position: fixed;
    right: 15px;
    bottom: 15px;
    width: 180px;
    height: 50px;
    font-family: "Roboto", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    color: #fff;
    background-color: #24caac;
    border: 1px #24caac solid;
    transition: all 0.5s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 48.0937px;

    i {
        font-size: 23px;
        margin-right: 10px;
    }

    @media (max-width: 991px) {
        width: 50px;
        height: 50px;
        border-radius: 50%;

        i {
            margin-right: 0;
        }
    }
}

.footer-lgpd {
    position: fixed;
    z-index: 99999;
    max-width: 1230px;
    transition: 0.3s ease-in-out;
    background: #fff;
    bottom: 25px;
    left: 10px;
    right: 10px;
    padding: 20px;
    box-shadow: 0 0 10px #aaa;
    border-radius: 12px;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 0 auto;
}
.footer-lgpd-opened {
    transition: 0.1s ease-in-out;
    -webkit-transform: translate(0);
    -ms-transform: translate(0);
    transform: translate(0);
}
.btn-lgpd {
    background-color: #cf9c57;
    color: #fff !important;
    text-align: center;
    border: 1px #cf9c57 solid;
    transition: all 0.5s ease;
    font-weight: 500;
    width: 300px;
    padding: 10px 15px;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: 1px;
    border-radius: 1.25rem;
    text-decoration: none !important;
}
.btn-lgpd:hover,
.btn-lgpd:focus {
    background-color: white !important;
    color: #cf9c57 !important;
    transition: all 0.5s ease;
}
.label-lgpd {
    color: white;
}
