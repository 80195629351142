.header {
    background-color: #fff;

    .level-1 {
        height: 43px;
        display: flex;
        align-items: center;
        border-bottom: 0.3px #00000030 solid;

        @media (max-width: 991px) {
            height: auto;
            padding: 10px 0px;
        }

        .wrapperBoxes {
            display: flex;
            align-items: center;
            justify-content: space-between;

            @media (max-width: 991px) {
                flex-direction: column;
            }

            .boxLeft {
                display: flex;
                align-items: center;

                .whatsapp {
                    margin-right: 26px;
                    display: flex;
                    align-items: center;

                    a {
                        display: flex;
                        align-items: center;
                    }

                    .imgWhatsapp {
                        width: 15px;
                        margin-right: 3px;
                    }
                }

                .phone {
                    display: flex;
                    align-items: center;

                    a {
                        display: flex;
                        align-items: center;
                    }

                    .imgPhone {
                        width: 15px;
                        margin-right: 5px;
                    }
                }
            }

            .boxRight {
                display: flex;
                align-items: center;

                @media (max-width: 991px) {
                    flex-direction: column;
                }

                .location {
                    margin-right: 41px;
                    display: flex;
                    align-items: center;

                    a {
                        display: flex;
                        align-items: center;
                    }

                    @media (max-width: 991px) {
                        margin-right: 0px;
                        margin-top: 5px;
                        margin-bottom: 5px;
                    }

                    .imgLocation {
                        width: 10px;
                        margin-right: 6px;
                    }
                }
                .socialMedia {
                    display: flex;
                    align-items: center;

                    a {
                        display: flex;
                        align-items: center;
                    }

                    .imgFacebook {
                        width: 7px;
                        margin-right: 13px;
                    }

                    .imgInstagram {
                        width: 14px;
                    }
                }
            }
        }

        .linkTop1 {
            font-family: "Poppins", sans-serif;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 23px;
            letter-spacing: 0em;
            text-align: left;
            color: #8c8c8c;
            transition: all 0.5s ease;

            &:hover,
            &:focus {
                color: #000;
            }
        }
    }

    .level-2 {
        border-bottom: 1px #23272c solid;

        .wrapperLevel2 {
            display: flex;
            align-items: center;
            justify-content: center;

            .navbar-brand {
                padding: 0 !important;
                margin: 0 !important;

                img {
                    width: 327px;
                    margin: 20px 0;

                    @media (max-width: 991px) {
                        margin: 30px 0;
                        width: 250px;
                    }
                }
            }

            .topMenuButton {
                display: none;
                border: 1px #23272c solid;
                color: #23272c;
                background-color: #fff;
                margin-left: 30px;
                transition: all 0.5s ease;
                outline: none !important;

                @media (max-width: 991px) {
                    display: block;
                }

                &:hover,
                &:focus {
                    color: #fff;
                    background-color: #23272c;
                }
            }
        }
    }

    .level-3 {
        background-color: #23272c;

        .navbar {
            padding: 0;
            margin: 0;

            .navbar-nav {
                justify-content: center;
                height: 68px;
                transition: all 0.5s ease;

                @media (max-width: 991px) {
                    height: auto;
                    padding: 15px 0px;
                }

                .nav-item {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .nav-link {
                        padding: 0;
                        position: relative;
                        font-family: "Poppins", sans-serif;
                        font-size: 15px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 23px;
                        letter-spacing: 0em;
                        color: #fff;
                        padding: 0px 65px;

                        @media (max-width: 991px) {
                            padding: 15px 40px;
                        }

                        @media(min-width: 992px) and (max-width: 1199px){
                            padding: 0px 50px;
                        }

                        &:before {
                            content: "";
                            position: absolute;
                            top: calc(50% - 3.5px);
                            right: 29px;
                            width: 7px;
                            height: 7px;
                            background-color: #cf9c57;
                            border-radius: 50%;
                            opacity: 0;
                            transition: all 0.5s ease;

                            @media (max-width: 991px) {
                                right: 29px;
                            }

                            @media(min-width: 992px) and (max-width: 1199px){
                                right: 39px;
                            }

                            @media(min-width: 1200px){
                                right: 54px;
                            }
                        }

                        &:hover {
                            &:before {
                                opacity: 1;
                            }
                        }
                    }

                    .link-ativo {
                        &:before {
                            opacity: 1 !important;
                        }
                    }
                }
            }
        }
    }
}

.header2 {
    visibility: hidden;
    z-index: 0;
    opacity: 0;
    position: fixed;
    transition: all 0.5s ease;
    width: 100%;
    top: 0px;
    background-color: #fff;

    .level-2 {
        border-bottom: 1px #23272c solid;

        .wrapperLevel2 {
            display: flex;
            align-items: center;
            justify-content: center;

            .navbar-brand {
                padding: 0 !important;
                margin: 0 !important;

                img {
                    width: 150px;
                    margin: 10px 0;

                    @media(max-width: 991px){
                        margin: 15px 0;
                    }
                }
            }

            .topMenuButton {
                display: none;
                border: 1px #23272c solid;
                color: #23272c;
                background-color: #fff;
                margin-left: 30px;
                transition: all 0.5s ease;
                outline: none !important;

                @media (max-width: 991px) {
                    display: block;
                }

                &:hover,
                &:focus {
                    color: #fff;
                    background-color: #23272c;
                }
            }
        }
    }

    .level-3 {
        background-color: #23272c;

        .navbar {
            padding: 0;
            margin: 0;

            .navbar-nav {
                justify-content: center;
                height: 45px;
                transition: all 0.5s ease;

                @media (max-width: 991px) {
                    height: auto;
                    padding: 15px 0px;
                }

                .nav-item {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .nav-link {
                        padding: 0;
                        position: relative;
                        font-family: "Poppins", sans-serif;
                        font-size: 15px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 23px;
                        letter-spacing: 0em;
                        color: #fff;
                        padding: 0px 65px;

                        @media (max-width: 991px) {
                            padding: 15px 40px;
                        }

                        @media(min-width: 992px) and (max-width: 1199px){
                            padding: 0px 50px;
                        }

                        &:before {
                            content: "";
                            position: absolute;
                            top: calc(50% - 3.5px);
                            right: 29px;
                            width: 7px;
                            height: 7px;
                            background-color: #cf9c57;
                            border-radius: 50%;
                            opacity: 0;
                            transition: all 0.5s ease;

                            @media (max-width: 991px) {
                                right: 29px;
                            }

                            @media(min-width: 992px) and (max-width: 1199px){
                                right: 39px;
                            }

                            @media(min-width: 1200px){
                                right: 54px;
                            }
                        }

                        &:hover {
                            &:before {
                                opacity: 1;
                            }
                        }
                    }

                    .link-ativo {
                        &:before {
                            opacity: 1 !important;
                        }
                    }
                }
            }
        }
    }
}
