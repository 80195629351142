.sectionPageServices {
    padding: 87px 0px 0px 0px;

    @media (max-width: 991px) {
        padding: 30px 0 0 0;
    }

    .titlePageServices {
        margin-bottom: 75px;

        @media (max-width: 991px) {
            margin-bottom: 30px;
        }

        .level1 {
            margin-bottom: 3px;

            .borderTitle {
                width: 44px;
                height: 8px;
                border-radius: 2.66667px;
                background-color: #cf9c57;
                margin: 0 auto;
            }
        }

        .level2 {
            font-size: 24px;
            font-style: normal;
            font-weight: 300;
            line-height: 36px;
            letter-spacing: 0em;
            text-align: center;
            color: #23272c;
            margin-top: 3px;
        }

        .level3 {
            font-size: 36px;
            font-style: normal;
            font-weight: 500;
            line-height: 54px;
            letter-spacing: 0em;
            text-align: center;
            color: #cf9c57;
            margin-top: -10px;
        }
    }

    .serviceHomeBox {
        flex: 0 0 33.333333333%;
        max-width: 33.333333333%;
        padding: 0px 15px;

        @media (max-width: 991px) {
            flex: 0 0 100%;
            max-width: 100%;
        }

        @media (min-width: 992px) and (max-width: 1199px) {
            flex: 0 0 33.3%;
            max-width: 33.3%;
        }

        .boxService {
            padding: 33.5px 44px;
            border: 0.5px #000000 solid;
            border-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 50px;
            overflow: hidden;
            transition: all 0.5s ease;
            will-change: transform;
            height: 198px;

            @media (max-width: 991px) {
                margin-bottom: 30px;
            }

            @media (max-width: 1599px) {
                padding: 30px 22px;
            }

            &:hover,
            &:focus {
                background-color: #23272c;
                transform: scale(1.03);

                .polygon {
                    opacity: 1;
                }

                .title {
                    color: #fff;

                    &::before {
                        opacity: 1 !important;
                    }
                }
            }

            .polygon {
                position: absolute;
                bottom: 9px;
                right: 6px;
                opacity: 0;
                transition: all 0.5s ease;
                width: 13px;
            }

            .image {
                display: flex;
                align-items: center;
                justify-items: center;

                img {
                    height: 120px;
                    width: 120px;
                    border-radius: 50%;
                    margin-right: 23px;

                    @media (max-width: 1365px) {
                        height: 100px;
                        width: 100px;
                        margin-right: 15px;
                    }

                    @media (min-width: 1366px) and (max-width: 1599px) {
                        height: 100px;
                        width: 100px;
                        margin-right: 15px;
                    }
                }
            }

            .title {
                font-family: "Montserrat", sans-serif;
                font-size: 24px;
                line-height: 29px;
                font-style: normal;
                font-weight: 300;
                letter-spacing: 0em;
                text-align: center;
                color: #000000;
                transition: all 0.5s ease;
                position: relative;
                width: 135px;

                @media(max-width: 1199px){
                    font-size: 20px;
                    line-height: 25px;
                }

                @media(min-width: 1200px) and (max-width: 1599px){
                    font-size: 22px;
                    line-height: 27px;
                }

                &::before {
                    content: "";
                    position: absolute;
                    bottom: -18px;
                    left: calc(50% - 30.5px);
                    opacity: 0;
                    width: 61px;
                    background-color: #cf9c57;
                    height: 1px;
                    transition: all 0.5s ease;
                }
            }
        }
    }
}

.modalServices {

    .modal-content{
        border: 0.5px solid #000000 !important;
        border-radius: 10px !important;
    }

    .modal-body {
        padding: 65px 90px 30px 90px;

        @media(max-width: 991px){
            padding: 30px;
        }
    }

    .closeModal {
        position: absolute;
        top: 30px;
        right: 14px;
        width: 85px;
        height: 49px;
        background-color: #fff;
        border: none !important;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        font-family: 'Montserrat', sans-serif;
        font-size: 35px;
        font-style: normal;
        font-weight: 300;
        line-height: 44px;
        letter-spacing: 0em;
        text-align: center;

        @media(max-width: 991px){
            width: 60px;
            height: 24px;
            font-size: 30px;
            line-height: 41px;
        }
    }

    .titleModalService {
        display: flex;
        align-items: center;
        margin-bottom: 65px;
        justify-content: start;

        @media (max-width: 991px) {
            margin-bottom: 30px;
            flex-direction: column;
            justify-content: center;
        }

        .titleImage {
            img {
                height: 120px;
                width: 120px;
                margin-right: 60px;
                border-radius: 50%;
                min-width: 120px;

                @media (max-width: 991px) {
                    margin-right: 0px;
                    margin-bottom: 15px;
                }
            }
        }

        .titleText {
            font-family: "Montserrat", sans-serif;
            font-size: 48px;
            font-style: normal;
            font-weight: 300;
            line-height: 59px;
            letter-spacing: 0em;
            text-align: center;
            border-bottom: 1px #cf9c57 solid;
        }
    }

    .contentModalService {
        font-family: "Poppins", sans-serif;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 36px;
        letter-spacing: 0em;
        text-align: left;
        color: #23272c;
        margin-bottom: 65px;

        @media(max-width: 991px){
            margin-bottom: 30px;
        }
    }

    .whatsappModalService {
        display: flex;
        align-items: center;
        justify-content: center;

        a {
            img {
                width: 284px;
            }
        }
    }
}
