.sectionPageAbout {
    .titlePageAbout {
        margin-bottom: 75px;
        margin-top: 87px;

        @media (max-width: 991px) {
            margin-bottom: 15px;
            margin-top: 30px;
        }

        .level1 {
            margin-bottom: 3px;

            .borderTitle {
                width: 44px;
                height: 8px;
                border-radius: 2.66667px;
                background-color: #cf9c57;
                margin: 0 auto;
            }
        }

        .level2 {
            font-size: 24px;
            font-style: normal;
            font-weight: 300;
            line-height: 36px;
            letter-spacing: 0em;
            text-align: center;
            color: #23272c;
            margin-top: 8px;
        }

        .level3 {
            font-size: 36px;
            font-style: normal;
            font-weight: 500;
            line-height: 54px;
            letter-spacing: 0em;
            text-align: center;
            color: #cf9c57;
            margin-top: -10px;
        }
    }

    .descriptionPageAbout {
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px;
        letter-spacing: 0em;
        text-align: left;
        color: #23272c;
        margin-bottom: 67px;

        @media(max-width: 991px){
            margin-bottom: 30px;
        }
    }

    .photosPageAbout{
        position: relative;

        .boxPhoto{
            cursor: pointer;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            height: 391px;
        }

        .passadoresAbout {
            position: absolute;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            top: calc(50% - 20px);

            .container {
                position: absolute;
            }
        }

        .prevAbout {
            position: absolute;
            left: 15px;
            cursor: pointer;
            top: calc(50% - 20px);
            width: 40px;
            height: 40px;
            display: flex;
            vertical-align: middle;
            justify-content: center;
            align-items: center;
            transition: all 0.5s ease;
            background-color: transparent;
            border: 1px #cf9c57 solid;
            z-index: 2;
            @media (max-width: 1365px) {
                left: 15px;
            }
            &:hover,
            &:focus {
                background-color: #cf9c57;
                color: #23272c;
                img {
                    filter: brightness(0) invert(1);
                }
            }
            img {
                transform: rotate(180deg);
                width: 16px;
            }
        }

        .nextAbout {
            position: absolute;
            right: 15px;
            top: calc(50% - 20px);
            cursor: pointer;
            width: 40px;
            height: 40px;
            vertical-align: middle;
            justify-content: center;
            align-items: center;
            display: flex;
            background-color: transparent;
            transition: all 0.5s ease;
            border: 1px #cf9c57 solid;
            z-index: 2;
            @media (max-width: 1365px) {
                right: 15px;
            }
            &:hover,
            &:focus {
                background-color: #cf9c57;
                color: #23272c;
                img {
                    filter: brightness(0) invert(1);
                }
            }
            img {
                width: 16px;
            }
        }
    }

    .boxMeetStaff {
        margin: 96px 0px 78px 0px;
        height: 159px;
        background-image: url("/images/sectionStaff.png");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        display: flex;
        align-items: center;
        justify-content: center;

        @media(max-width: 991px){
            margin: 30px 0px;
        }

        .meetStaff {
            a {
                button {
                    width: 251px;
                    height: 42px;
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: 23px;
                    letter-spacing: 0em;
                    color: #ffffff;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: transparent;
                    border: 1px solid #FFFFFF;

                    @media(max-width: 540px){
                        width: 285px;
                    }

                    img {
                        width: 20px;
                        margin-left: 12px;
                    }
                }
            }
        }
    }

    .boxLearnServices {
        margin: 96px 0px;
        height: 159px;
        background-image: url("/images/learnServices.png");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        display: flex;
        align-items: center;
        justify-content: center;

        @media(max-width: 991px){
            margin: 30px 0px 30px 0px;
        }

        .learnServices {
            a {
                button {
                    width: 266px;
                    height: 42px;
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: 23px;
                    letter-spacing: 0em;
                    color: #ffffff;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: transparent;
                    border: 1px solid #FFFFFF;

                    @media(max-width: 540px){
                        width: 285px;
                    }

                    img {
                        width: 20px;
                        margin-left: 12px;
                    }
                }
            }
        }
    }
}
