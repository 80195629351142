.section-banners {
    .container-banner {
        position: relative;
        .imagem {
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;
            height: calc(100vh - 254px);
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            @media (max-width: 991px) {
                height: 300px;
            }
        }

        .descricao-banner {
            overflow: hidden;
            font-size: 72px;
            font-style: normal;
            font-weight: 300;
            line-height: 88px;
            letter-spacing: 0em;
            text-align: center;
            z-index: 2;
            color: #cf9c57;
            padding: 5rem;
            opacity: 0;
            transform: translateY(80px);
            transition: all 0.5s ease;

            @media (max-width: 991px) {
                font-size: 45px;
                line-height: 60px;
                padding: 5rem 4rem;
            }

            .link-banner {
                button {
                    font-size: 15px;
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 36px;
                    letter-spacing: 0em;
                    text-align: center;
                    color: #fff;
                    background-color: transparent;
                    transition: all 0.5s ease;
                    border-radius: 10px;
                    border: 3px solid #fff;
                    padding: 3px 12.5px;

                    @media (max-width: 991px) {
                        font-size: 18px;
                        line-height: 30px;
                    }

                    &:hover,
                    &:focus {
                        background-color: #fff;
                        color: #23272c;
                    }
                }
            }
        }

        .passadores {
            position: absolute;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            top: calc(50% - 20px);

            .container {
                position: absolute;
            }
        }

        .prev {
            position: absolute;
            left: -58px;
            cursor: pointer;
            top: calc(50% - 20px);
            width: 40px;
            height: 40px;
            display: flex;
            vertical-align: middle;
            justify-content: center;
            align-items: center;
            transition: all 0.5s ease;
            background-color: transparent;
            border: 1px #cf9c57 solid;
            z-index: 2;
            @media (max-width: 1365px) {
                left: 15px;
            }
            &:hover,
            &:focus {
                background-color: #cf9c57;
                color: #23272c;
                img {
                    filter: brightness(0) invert(1);
                }
            }
            img {
                transform: rotate(180deg);
                width: 16px;
            }
        }

        .next {
            position: absolute;
            right: -58px;
            top: calc(50% - 20px);
            cursor: pointer;
            width: 40px;
            height: 40px;
            vertical-align: middle;
            justify-content: center;
            align-items: center;
            display: flex;
            background-color: transparent;
            transition: all 0.5s ease;
            border: 1px #cf9c57 solid;
            z-index: 2;
            @media (max-width: 1365px) {
                right: 15px;
            }
            &:hover,
            &:focus {
                background-color: #cf9c57;
                color: #23272c;
                img {
                    filter: brightness(0) invert(1);
                }
            }
            img {
                width: 16px;
            }
        }
    }
}

.sectionServices {
    margin: 60px 0px 95px 0px;

    @media (max-width: 991px) {
        margin: 30px 0px;
    }

    .titleServices {
        margin-bottom: 60px;

        @media (max-width: 991px) {
            margin-bottom: 30px;
        }

        .level1 {
            margin-bottom: 3px;

            .borderTitle {
                width: 44px;
                height: 8px;
                border-radius: 2.66667px;
                background-color: #cf9c57;
                margin: 0 auto;
            }
        }

        .level2 {
            font-size: 24px;
            font-style: normal;
            font-weight: 300;
            line-height: 36px;
            letter-spacing: 0em;
            text-align: center;
            color: #23272c;
            margin-top: 3px;
        }

        .level3 {
            font-size: 36px;
            font-style: normal;
            font-weight: 500;
            line-height: 54px;
            letter-spacing: 0em;
            text-align: center;
            color: #cf9c57;
            margin-top: -10px;
        }
    }

    .serviceHomeBox{
        flex: 0 0 33.333333333%;
        max-width: 33.333333333%;
        padding: 0px 15px;

        @media(max-width: 991px){
            flex: 0 0 100%;
            max-width: 100%;
        }

        @media(min-width: 992px) and (max-width: 1199px){
            flex: 0 0 33.3%;
            max-width: 33.3%;
        }

        .boxService {
            padding: 33.5px 44px;
            border: 0.5px #000000 solid;
            border-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 17px;
            overflow: hidden;
            transition: all 0.5s ease;
            will-change: transform;
            height: 198px;

            @media (max-width: 991px) {
                margin-bottom: 15px;
            }

            @media (max-width: 1599px) {
                padding: 30px 22px;
            }

            &:hover,
            &:focus {
                background-color: #23272c;
                transform: scale(1.03);

                .polygon {
                    opacity: 1;
                }

                .title {
                    color: #fff;

                    &::before {
                        opacity: 1 !important;
                    }
                }
            }

            .polygon {
                position: absolute;
                bottom: 9px;
                right: 6px;
                opacity: 0;
                transition: all 0.5s ease;
                width: 13px;
            }

            .image {
                display: flex;
                align-items: center;
                justify-items: center;

                img {
                    width: 120px;
                    height: 120px;
                    border-radius: 50%;
                    margin-right: 23px;

                    @media (max-width: 1365px) {
                        height: 100px;
                        width: 100px;
                        margin-right: 15px;
                    }

                    @media (min-width: 1366px) and (max-width: 1599px) {
                        height: 100px;
                        width: 100px;
                        margin-right: 15px;
                    }
                }
            }

            .title {
                font-family: "Montserrat", sans-serif;
                font-size: 24px;
                line-height: 29px;
                font-style: normal;
                font-weight: 300;
                letter-spacing: 0em;
                text-align: center;
                color: #000000;
                transition: all 0.5s ease;
                position: relative;
                width: 135px;

                @media(max-width: 1199px){
                    font-size: 20px;
                    line-height: 25px;
                }

                @media(min-width: 1200px) and (max-width: 1599px){
                    font-size: 22px;
                    line-height: 27px;
                }

                &::before {
                    content: "";
                    position: absolute;
                    bottom: -18px;
                    left: calc(50% - 30.5px);
                    opacity: 0;
                    width: 61px;
                    background-color: #cf9c57;
                    height: 1px;
                    transition: all 0.5s ease;
                }
            }
        }
    }

    .seeServices {
        margin-top: 23px;
        text-align: center;

        @media (max-width: 991px) {
            margin-top: 15px;
        }

        a {
            button {
                background-color: #cf9c57;
                border: 1px #cf9c57 solid;
                color: #fff;
                transition: all 0.5s ease;
                font-family: "Roboto", sans-serif;
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: 22px;
                letter-spacing: 0em;
                text-align: center;
                width: 150px;
                height: 45px;
                border-radius: 48.0937px;

                &:hover,
                &:focus {
                    background-color: #fff;
                    color: #cf9c57;
                }
            }
        }
    }
}

.sectionAbout {
    .boxAbout {
        background-image: url("/images/woodenBackground.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        padding: 80px 125px;

        @media (max-width: 1199px) {
            padding: 30px;
        }

        .wrapperAbout {
            opacity: 0.75;

            .borderTop {
                width: 44px;
                height: 8px;
                background-color: #cf9c57;
                border-radius: 2.66667px;
                margin-bottom: 15px;
            }

            .aboutTitle {
                font-size: 34px;
                font-style: normal;
                font-weight: 200;
                line-height: 41px;
                letter-spacing: 0em;
                text-align: left;
                color: #fff;
                margin-bottom: 17px;

                span {
                    font-weight: 700;
                    color: #cf9c57;
                }
            }

            .aboutDescription {
                font-size: 20px;
                font-style: normal;
                font-weight: 200;
                line-height: 35px;
                letter-spacing: 0em;
                text-align: left;
                color: #fff;
            }

            .learnMore {
                margin-top: 17px;

                a {
                    button {
                        border: 1px solid #ffffff;
                        font-size: 17px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 25px;
                        letter-spacing: 0em;
                        color: #fff;
                        background-color: transparent;
                        width: 146px;
                        height: 50px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 6px;

                        &:hover{
                            background-color: #cf9c57;
                            border: 1px #cf9c57 solid;
                        }

                        img {
                            width: 23px;
                            margin-left: 10px;
                        }
                    }
                }
            }
        }
    }

    .imageAbout {
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        height: 100%;

        @media (max-width: 1199px) {
            height: 350px;
        }
    }
}

.sectionFounderWords {
    margin: 90px 0px;

    @media (max-width: 991px) {
        margin: 30px 0px;
    }

    .imgFounder {
        height: 558px;
        background-size: cover;
        background-position: center top;
        background-repeat: no-repeat;
        border: 0.3px solid #000000;

        @media (max-width: 767px) {
            height: 450px;
        }
    }

    .wrapperFounder {
        .titleWords {
            font-size: 32px;
            font-style: normal;
            font-weight: 400;
            line-height: 50px;
            letter-spacing: 0em;
            text-align: left;
            color: #cf9c57;
            margin-bottom: 15px;
            padding-left: 38px;

            @media (max-width: 991px) {
                padding-left: 0;
                margin-top: 30px;
            }
        }

        .subtitleWords {
            font-size: 32px;
            font-style: normal;
            font-weight: 400;
            line-height: 50px;
            letter-spacing: 0em;
            text-align: left;
            color: #23272c;
            margin-bottom: 22px;
            padding-left: 38px;

            @media (max-width: 991px) {
                padding-left: 0;
                margin-top: 30px;
            }
        }

        .dividerWords {
            background-color: #cf9c57;
            height: 1px;
            margin-bottom: 35px;
            margin-left: 13px;

            @media (max-width: 991px) {
                margin-bottom: 30px;
                margin-left: 0;
            }
        }

        .descriptionWords {
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 32px;
            letter-spacing: 0em;
            text-align: left;
            color: #23272c;
            padding-left: 41px;

            @media (max-width: 991px) {
                padding-left: 0;
            }
        }
    }
}

.sectionMeetStaff {
    margin-bottom: 80px;

    @media (max-width: 991px) {
        margin-bottom: 30px;
    }

    .boxMeetStaff {
        height: 159px;
        background-image: url("/images/sectionStaff.png");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        display: flex;
        align-items: center;
        justify-content: center;

        .meetStaff {
            a {
                button {
                    width: 311px;
                    height: 42px;
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: 23px;
                    letter-spacing: 0em;
                    color: #ffffff;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: transparent;
                    border: 1px solid #FFFFFF;

                    @media(max-width: 540px){
                        width: 285px;
                    }

                    img {
                        width: 20px;
                        margin-left: 12px;
                    }
                }
            }
        }
    }
}
