.sectionPolicy {
    .titlePolicy {
        margin-top: 87px;

        @media (max-width: 991px) {
            margin-top: 30px;
        }

        .level1 {
            margin-bottom: 3px;

            .borderTitle {
                width: 44px;
                height: 8px;
                border-radius: 2.66667px;
                background-color: #cf9c57;
                margin: 0 auto;
            }
        }

        .level2 {
            font-size: 24px;
            font-style: normal;
            font-weight: 300;
            line-height: 36px;
            letter-spacing: 0em;
            text-align: center;
            color: #23272c;
            margin-top: 8px;
        }

        .level3 {
            font-size: 36px;
            font-style: normal;
            font-weight: 500;
            line-height: 54px;
            letter-spacing: 0em;
            text-align: center;
            color: #cf9c57;
            margin-top: -10px;
        }
    }

    .updatedPolicy {
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px;
        letter-spacing: 0em;
        text-align: left;
        color: #23272c;
        margin-top: 30px;

        @media(max-width: 991px){
            margin-top: 30px;
        }
    }

    .contentPolicy {
        text-align: justify;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px;
        letter-spacing: 0em;
        color: #23272c;
        margin-top: 30px;
        margin-bottom: 67px;

        @media(max-width: 991px){
            margin-bottom: 30px;
        }
    }
}
